<template>
  <div class="uk-margin-top">
    <div
      v-if="!isCanAccessUser(`view`, `Daily Resume`)"
      class="uk-card uk-card-default uk-padding-small kandang-card"
    >
      <div
        class="uk-grid-small"
        uk-grid
      >
        <div class="uk-width-2-3">
          <div>Daily Resume</div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="uk-card uk-card-default kandang-card"
    >
      <div class="uk-margin-top">
        <div class="filter-container">
          <div class="uk-form-horizontal">
            <div uk-grid>
              <div class="uk-width-1-2@s uk-first-column">
                <label
                  class="uk-form-label filter-margin"
                  for="cari"
                >Nama Kandang</label>
                <div class="uk-form-controls">
                  <input
                    id="cari"
                    v-model="metaFilter.cage"
                    class="uk-input"
                    type="text"
                    placeholder="Cari nama kandang"
                  >
                </div>
              </div>
              <div class="uk-width-1-2@s">
                <label class="uk-form-label filter-margin"> Jenis Kandang </label>
                <div class="uk-form-controls">
                  <multiselect
                    id="cage_category"
                    v-model="cageType"
                    class="uk-width-auto"
                    label="name"
                    name="name"
                    track-by="name"
                    placeholder="Semua"
                    open-direction="bottom"
                    :options="dataListCage.data"
                    :searchable="true"
                    :loading="dataListCage.isLoading"
                    :close-on-select="true"
                    :max-height="500"
                    :show-no-results="true"
                    @search-change="handleCageType"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                </div>
              </div>
              <div
                v-if="metaFilter.cageCategory" 
                class="uk-width-1-2@s"
              >
                <label class="uk-form-label filter-margin"> Tahapan </label>
                <div class="uk-form-controls">
                  <multiselect 
                    id="category-type" 
                    v-model="metaFilter.categoryChoose" 
                    label="name"
                    name="category-type" 
                    track-by="name" 
                    placeholder="Semua" 
                    open-direction="bottom" 
                    :options="dataListStages.data" 
                    :searchable="true" 
                    :loading="dataListStages.isLoading"
                    :close-on-select="true" 
                    :max-height="500" 
                    :show-no-results="true"
                    :disabled="!metaFilter.cageCategory"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@s uk-text-right uk-margin-top">
            <button
              class="uk-button uk-button-default uk-margin-left"
              @click="clearFilter()"
            >
              <span
                uk-icon="icon: close; ratio: 0.9"
                class="uk-margin-small-right"
              />Clear
            </button>
            <button
              class="uk-button uk-button-primary uk-margin-left"
              @click="handleFind()"
            >
              <span
                uk-icon="icon: search; ratio: 0.9"
                class="uk-margin-small-right"
              />Cari
            </button>
          </div>
        </div>

        <div class="uk-margin-top">
          <div class="uk-overflow-auto">
            <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider uk-table-middle">
              <thead class="thead-table-paranje">
                <tr>
                  <th
                    class="table-header uk-text-center uk-table-expand uk-width-small"
                    rowspan="2"
                  >
                    <span class="uk-text-bold">Nama Kandang </span>
                  </th>
                  <th
                    class="table-header uk-text-center uk-table-expand uk-width-small"
                    rowspan="2"
                  >
                    <div class="uk-flex uk-flex-between">
                      <span class="uk-text-bold">Tahapan</span>
                      <div>
                        <img
                          v-lazy="`${images}/icon/sort-green.svg`"
                          alt="icon sort"
                          class="img-click"
                        >
                        <div uk-dropdown="mode: click">
                          <ul class="uk-nav uk-dropdown-nav">
                            <li
                              v-for="(item, i) in dataSorting"
                              :key="i"
                              class="uk-margin-bottom"
                              @click="handleSortStages(item.value)"
                            >
                              <span class="img-click">
                                {{ item.name }}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th
                    style="width: 160px"
                    class="table-header uk-table-expand uk-text-center"
                    colspan="2"
                  >
                    <span class="uk-text-bold">Bobot Sampel (gr)</span>
                  </th>
                  <th
                    style="width: 160px"
                    class="table-header uk-table-expand uk-text-center"
                    colspan="2"
                  >
                    <span class="uk-text-bold">Daily Intake (gr)</span>
                  </th>
                  <th
                    style="width: 160px"
                    class="table-header uk-table-expand uk-text-center"
                    colspan="2"
                  >
                    <span class="uk-text-bold">Feed Intake (gr)</span>
                  </th>
                  <th
                    style="width: 160px"
                    class="table-header uk-table-expand uk-text-center"
                    colspan="2"
                  >
                    <span class="uk-text-bold">Deplesi Harian</span>
                  </th>
                  <th
                    style="width: 135px"
                    class="table-header uk-text-center uk-width-small"
                    rowspan="2"
                  >
                    <span class="uk-text-bold">Deplesi</span>
                  </th>
                  <th
                    style="width: 160px"
                    class="table-header uk-table-expand uk-text-center"
                    colspan="2"
                  >
                    <span class="uk-text-bold">FCR</span>
                  </th>
                  <th
                    style="width: 160px"
                    class="table-header uk-table-expand uk-text-center"
                    colspan="2"
                  >
                    <span class="uk-text-bold">Daily Gain</span>
                  </th>
                  <th
                    style="width: 135px"
                    class="table-header uk-text-center uk-width-small"
                    rowspan="2"
                  >
                    <span class="uk-text-bold">Indeks Prestasi</span>
                  </th>
                </tr>
                <tr>
                  <td class="act-std uk-text-center">
                    Act
                  </td>
                  <td class="act-std uk-text-center">
                    Std
                  </td>
                  <td class="act-std uk-text-center">
                    Act
                  </td>
                  <td class="act-std uk-text-center">
                    Std
                  </td>
                  <td class="act-std uk-text-center">
                    Act
                  </td>
                  <td class="act-std uk-text-center">
                    Std
                  </td>
                  <td class="act-std uk-text-center">
                    Mati
                  </td>
                  <td class="act-std uk-text-center">
                    Afkir
                  </td>
                  <td class="act-std uk-text-center">
                    Act
                  </td>
                  <td class="act-std uk-text-center">
                    Std
                  </td>
                  <td class="act-std uk-text-center">
                    Act
                  </td>
                  <td class="act-std uk-text-center">
                    Std
                  </td>
                </tr>
              </thead>
              <template v-if="!dataList.isLoading">
                <tbody
                  v-if="dataList.data.length > 0"
                  style="border: 1px solid #e5e5e5"
                >
                  <tr
                    v-for="(item, i) in dataList.data"
                    :key="i"
                    style="cursor: pointer"
                    @click="goToDetail(item)"
                  >
                    <td
                      class="uk-text-truncate border-right" 
                      :class="{ 'label-red': isYesterday(item.work_date_start) }"
                      :uk-tooltip="item.name"
                    >
                      {{ item.name || "-" }}
                    </td>
                    <td>{{ item.log_activity_name || "-" }}</td>
                    <td :class="{ 'act-column': true, 'uk-text-center': true, 'label-red': item.group_body_weight_actual < item.group_body_weight_standard }">
                      {{ formatFloat(item.group_body_weight_actual) || 0 }}
                    </td>
                    <td class="std-column uk-text-center">
                      {{ thousandSeparator(item.group_body_weight_standard) || 0 }}
                    </td>
                    <td :class="{ 'act-column': true, 'uk-text-center': true, 'label-red': item.group_daily_intake_actual < item.group_daily_intake_standard }">
                      {{ formatFloat(Math.round(item.group_daily_intake_actual)) || 0 }}
                    </td>
                    <td class="std-column uk-text-center">
                      {{ item.group_daily_intake_standard || 0 }}
                    </td>
                    <td :class="{ 'act-column': true, 'uk-text-center': true, 'label-red': item.group_feed_intake_actual < item.group_feed_intake_standard }">
                      {{ formatFloat(Math.round(item.group_feed_intake_actual)) || 0 }}
                    </td>
                    <td class="std-column uk-text-center">
                      {{ item.group_feed_intake_standard || 0 }}
                    </td>
                    <td :class="{ 'act-column': true, 'uk-text-center': true, 'label-red': item.group_deplesi_died < item.group_deplesi_culling }">
                      {{ item.group_deplesi_died || 0 }}
                    </td>
                    <td class="act-column rmv-column uk-text-center">
                      {{ item.group_deplesi_culling || 0 }}
                    </td>
                    <td class="act-column rmv-column uk-text-center">
                      {{ item.group_deplesi_acumulative || 0 }}
                    </td>
                    <td
                      :class="{
                        'act-column': true,
                        'uk-text-center': true,
                        'label-red': item.group_fcr_actual > item.group_fcr_standard,
                        'label-green': item.group_fcr_actual <= item.group_fcr_standard
                      }"
                    >
                      {{ formatFloat(item.group_fcr_actual) || 0 }}
                    </td>
                    <td class="std-column uk-text-center">
                      {{ formatFloat(item.group_fcr_standard) || 0 }}
                    </td>
                    <td class="act-column uk-text-center">
                      {{ item.dg_actual || 0 }}
                    </td>
                    <td class="std-column uk-text-center">
                      {{ item.dg_standard || 0 }}
                    </td>
                    <td class="act-column uk-text-center">
                      {{ formatFloat(Math.round(item.ip_actual)) || 0 }}
                    </td>
                  </tr>
                </tbody>
                <empty-table
                  v-else
                  :colspan="21"
                  :is-dailyresume="isDailyResume"
                />
              </template>
              <template v-else>
                <loading-table
                  :colspan="21"
                  :is-dailyresume="isDailyResume"
                />
              </template>
            </table>
          </div>
        </div>
        <pagination
          :total-data="data.meta.total_count"
          :change-limit="changeLimit"
          :change-page="changePage"
          :current-page="meta.page"
          :current-limit="meta.limit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex"
import { PREFIX_IMAGE, SORTING } from "@/utils/constant"
import { isCanAccess } from "@/utils/auth"
import Multiselect from "vue-multiselect"
import EmptyTable from "@/components/globals/table/EmptyTable"
import LoadingTable from "@/components/globals/table/LoadingTable"
import Pagination from "@/components/globals/Pagination"
import { float3Decimals, thousandSeparator } from "@/utils/formater"
import "vue-multiselect/dist/vue-multiselect.min.css"

export default {
  components: {
    Multiselect,
    EmptyTable,
    LoadingTable,
    Pagination
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      dataSorting: SORTING,
      isLoading: true,
      metaFilter: {
        cage: "",
        activity: "",
        sort: "",
        cageCategory: "",
        categoryChoose: {}
      },
      cageType: null,
      dataListStages: {
        data: [],
        isLoading: false
      },
      dataList: {
        data: [],
        isLoading: true
      },
      dataListCage: {
        data: [],
        isLoading: true
      },
      debounce: null,
      isDailyResume: true
    }
  },
  computed: {
    ...mapGetters({
      data: "cycle/daily_resume",
      meta: "cycle/metaResume",
      metaCage: "cageCategory/meta",
      metaActivity: "activity/meta",
      activities: "activity/activities",
      cageCategorys: "cageCategory/cageCategorys"
    })
  },
  watch: {
    async meta() {
      this.dataList = { ...this.dataList, isLoading: true }

      await this.getData(this.meta)
      this.dataList.data = this.data.data || []
      this.dataList.isLoading = false 
    },
    async cageType() {
      if (this.cageType) {
        this.dataListStages = { ...this.dataListStages, isLoading: true }
        await this.getActivity({
          ...this.metaActivity,
          is_all: true,
          cage_category_id: this.cageType.id
        })

        this.metaFilter = { ...this.metaFilter, cageCategory: this.cageType }
        this.dataListStages = { data: this.activities ? this.activities : [], isLoading: false }
      } else {
        return (this.metaFilter = { ...this.metaFilter, cageCategory: null, categoryChoose: null })
      }
    }
  },
  async mounted() {
    await this.getData(this.meta)
    await this.getCageCategory({ ...this.metaCage, status: true })

    this.dataList = { data: this.data ? this.data.data : [], isLoading: false }
    this.dataListCage = { data: this.cageCategorys.data ? this.cageCategorys.data : [], isLoading: false }
  },
  methods: {
    ...mapActions({
      getData: "cycle/getDailyResume",
      getActivity: "activity/getActivity",
      getCageCategory: "cageCategory/getCageCategory"
    }),
    ...mapMutations({
      setMetaResume: "cycle/SET_META_RESUME",
      setMetaCage: "cageCategory/SET_META"
    }),
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    formatFloat(number) {
      return float3Decimals(number)
    },
    thousandSeparator(number) {
      return thousandSeparator(number)
    },
    goToDetail(data) {
      this.$router.push(`/admin/kandang-ayam/detail/${data.id}`)
    },
    handleSortStages(value) {
      this.metaFilter = { ...this.metaFilter, sort: value }
      this.setMetaResume({
        ...this.meta,
        sort: value
      })
    },
    handleFind() {
      this.setMetaResume({
        ...this.meta,
        cage: this.metaFilter.cage || "",
        activity: this.metaFilter.categoryChoose ? this.metaFilter.categoryChoose.name : "",
        sort: "DESC",
        cage_category_id: this.metaFilter.cageCategory ? this.metaFilter.cageCategory.id : ""
      })
      this.isLoading = false
    },
    async handleCageType(value) {
      clearTimeout(this.debounce)
      this.debounce = await setTimeout(() => {
        this.getCageCategory({
          ...this.metaCage,
          status: true,
          name: value
        })
      }, 600)
    },
    async clearFilter() {
      this.metaFilter.cage = ''
      this.cageType = ""
      await this.handleFind()
      this.$router.go()
    },
    changeLimit(e) {
      this.setMetaResume({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMetaResume({
        ...this.meta,
        page: value
      })
    },
    isYesterday(date){
      const rawToday = new Date()
      const rawDateToCheck = new Date(date)
      const getDatePortion = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate())
      }

      const today = getDatePortion(rawToday)
      const dateToCheck = getDatePortion(rawDateToCheck)
      
      const checkYesterday = dateToCheck < today
      return checkYesterday
    }
  }
}
</script>

<style scoped>
.filter-container {
  border-bottom: none;
  padding-bottom: 0;
}
.table-header {
  vertical-align: inherit;
  min-width: 125px !important;
  width: 125px;
}
.uk-text-center {
  border-left: 1px solid #b0dfcc;
  border-right: 1px solid #b0dfcc;
}

.border-right {
  border-right: 1px solid #b0dfcc;
}

.act-std {
  padding: 0;
  height: 24px;
}
.act-column {
  border-right: none;
  min-width: 108px;
}
.std-column {
  border-left: none;
  background: #f0f5f3;
  min-width: 105px;
}
.rmv-column {
  border-left: none;
}

tbody {
  display: block;
  height: auto;
  overflow: auto;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: auto;
}

tbody tr td {
  min-width: 100px;
}
</style>
